<template>
  <div :class="['expander-card', { 'is-expanded': expanded, 'has-content': hasContent }]">
    <div :class="['ratio', `ratio-${imgRatio}`]">
      <div>
        <a v-if="imgHref" :href="imgHref" :target="imgTarget">
          <img class="expander-card-img" :src="imgSrc" :alt="imgAlt"/>
        </a>

        <img v-else class="expander-card-img" :src="imgSrc" :alt="imgAlt"/>
      </div>
    </div>

    <div class="expander-card-content">
      <div class="content-header" :aria-hidden="hasContent">
        <slot name="header"/>
      </div>

      <div class="content-inner" v-if="hasContent">
        <div class="content-header">
          <slot name="header"/>
        </div>

        <transition-expand>
          <div class="content-body" v-if="expanded">
            <slot/>
          </div>
        </transition-expand>
      </div>
    </div>

    <a v-if="$slots.default" class="expander-card-expander" href="#" @click.prevent="toggle"><b-icon icon="plus" scale="1.5"/></a>
  </div>
</template>

<script>
import SCSS_VARIABLES from '@/assets/js/modules/scss-variables'

export default {
  name: 'ExpanderCard',
  props: {
    imgSrc: {
      type: String,
      default: null
    },
    imgAlt: {
      type: String,
      default: null
    },
    imgRatio: {
      type: String,
      default: '1x1',
      validator: value => SCSS_VARIABLES.vars['ratio-keys'].includes(value)
    },
    imgHref: {
      type: String,
      default: null
    },
    imgTarget: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  computed: {
    hasContent () {
      return this.$slots.default !== undefined
    }
  },
  methods: {
    toggle () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style lang="scss">
$expander-card-border-radius: $leaf-border-radius-lg !default;
$expander-card-bg: $white !default;

$expander-card-image-transition-in: transform ease-out 200ms !default;
$expander-card-image-transition-out: transform ease-out 200ms !default;

$expander-card-content-padding-y: $component-padding-y !default;
$expander-card-content-padding-x: $component-padding-x !default;
$expander-card-content-bg: $expander-card-bg !default;

$expander-card-content-body-gap: $spacer !default;
$expander-card-content-body-font-size: $font-size-sm !default;

$expander-card-expander-transition-in: transform cubic-bezier(0.35, 0.05, 0.4, 1.5) 500ms !default;
$expander-card-expander-transition-out: transform cubic-bezier(0.35, 0.05, 0.4, 1.5) 200ms !default;

.expander-card {
  @include make-leaf($expander-card-border-radius);
  position: relative;
  overflow: hidden;
  height: 100%;
  background-color: $expander-card-bg;

  .expander-card-img {
    display: block;
    margin: 0;
    width: 100%;
    max-width: none;
    transform-origin: bottom center;
    transform: scale(1);
    transition: $expander-card-image-transition-out;
  }

  .content-header,
  .content-body {
    > *:last-child {
      margin-bottom: 0;
    }
  }

  .expander-card-content {
    position: relative;
    padding: $expander-card-content-padding-y $expander-card-content-padding-x;
    background-color: $expander-card-content-bg;
    z-index: 2;

    .content-inner {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: inherit;
      background-color: inherit;

      .content-header {
        visibility: visible;
      }

      .content-body {
        font-size: $expander-card-content-body-font-size;

        &:before {
          display: block;
          content: '';
          height: $expander-card-content-body-gap;
        }
      }
    }
  }

  .expander-card-expander {
    display: block;
    position: absolute;
    bottom: $expander-card-border-radius * 0.65;
    right: $expander-card-border-radius * 0.65;
    transition: $expander-card-expander-transition-out;
    z-index: 3;
  }

  &.has-content {
    .expander-card-content {
      .content-header {
        visibility: hidden;
      }

      .content-inner {
        .content-header {
          visibility: visible;
        }
      }
    }
  }

  &:hover {
    .expander-card-img {
      transform: scale(1.05);
      transition: $expander-card-image-transition-in;
    }
  }

  &.is-expanded {
    .expander-card-expander {
      transform: rotate(-135deg);
      transition: $expander-card-expander-transition-in;
    }
  }
}
</style>
